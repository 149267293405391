<template>
  <div>
    <!-- Table -->
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="invoices"
      item-key="name"
      show-select
      class="elevation-1"
    >
      <template v-slot:item.noInvoice="{item}">
        <v-col
          v-if="$vuetify.breakpoint.smAndUp"
          no-gutters
        >
          <p style="color:black">
            {{ item.noInvoice === "" ? "--" : item.noInvoice }}
          </p>
          <p style="margin-top:-16px">
            {{ item.createdAt === "" ? "--" : item.createdAt }}
          </p>
        </v-col>
      </template>
      <template v-slot:item.category="{item}">
        <v-col
          v-if="$vuetify.breakpoint.smAndUp"
          no-gutters
        >
          <p style="color:black">
            {{ item.category === "" ? "--" : item.category }}
          </p>
          <p style="margin-top:-16px">
            {{ item.tag === "" ? "--" : item.tag }}
          </p>
        </v-col>
      </template>
      <template v-slot:item.reciepents="{item}">
        <v-col
          v-if="$vuetify.breakpoint.smAndUp"
          no-gutters
        >
          <p style="color:black">
            {{ item.reciepents === "" ? "--" : item.reciepents }}
          </p>
          <p style="margin-top:-16px">
            {{ item.sentTo === "" ? "--" : item.sentTo }}
          </p>
        </v-col>
      </template>
      <template v-slot:item.createdBy="{item}">
        <v-col
          v-if="$vuetify.breakpoint.smAndUp"
          no-gutters
        >
          <p style="color:black">
            {{ item.createdBy === "" ? "--" : item.createdBy }}
          </p>
          <p style="margin-top:-16px">
            {{ item.email === "" ? "--" : item.email }}
          </p>
        </v-col>
      </template>
      <template v-slot:item.amount="{item}">
        <v-row
          v-if="$vuetify.breakpoint.smAndUp"
          no-gutters
          justify="center"
        >
          <v-col class="mr-2 mt-1">
            {{ item.amount === "" ? "--" : item.amount }}
          </v-col>
          <v-col>
            <v-btn
              class="action-btn me-4"
              small
              outlined
              :style="isDark 
                ? { 'border-color': '#5E5669AD' } 
                : { 'border-color': 'lightgray' }"
              color="primary"
              @click="toDetail"
            >
              <v-icon>{{ icons.mdiEye }}</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import useAppConfig from '@core/@app-config/useAppConfig'
import { ref } from '@vue/composition-api'
import { mdiEye } from '@mdi/js'

export default {
  name: 'ListInvoice',
  setup() {
    const { isDark } = useAppConfig()
    const search = ref('')

    return {
      isDark,
      search,
      icons: { mdiEye },
    }
  },
  data() {
    return {
      headers: [
        {
          text: 'No Invoice & Create time',
          value: 'noInvoice',
        },
        { text: 'Category & Tag', value: 'category' },
        { text: 'Received from/sent to', value: 'reciepents' },
        { text: 'Created by', value: 'createdBy' },
        { text: 'Amount', value: 'amount' },
      ],
      invoices: [
        {
          id: 1,
          noInvoice: 'INV/20201114/XX/XI/675206164',
          createdAt: '28 Aug 2022, 11:36',
          category: 'Loan',
          tag: 'Tag here',
          reciepents: 'CV. Citra Nusantara',
          sentTo: 'Nurdien',
          createdBy: 'Feri Sandria',
          email: 'email@gmail.com',
          amount: 'Rp. 24.353.445',
        },
        {
          id: 2,
          noInvoice: 'INV/20201114/XX/XI/675206164',
          createdAt: '28 Aug 2022, 11:36',
          category: 'Loan',
          tag: 'Tag here',
          reciepents: 'CV. Citra Nusantara',
          sentTo: 'Nurdien',
          createdBy: 'Feri Sandria',
          email: 'email@gmail.com',
          amount: 'Rp. 24.353.445',
        },
        {
          id: 3,
          noInvoice: 'INV/20201114/XX/XI/675206164',
          createdAt: '28 Aug 2022, 11:36',
          category: 'Loan',
          tag: 'Tag here',
          reciepents: 'CV. Citra Nusantara',
          sentTo: 'Nurdien',
          createdBy: 'Feri Sandria',
          email: 'email@gmail.com',
          amount: 'Rp. 24.353.445',
        },
      ],
    }
  },
  methods: {
    toDetail() {
      this.$router.push({ name: 'detail-invoice' })
    },
  },
}
</script>

<style>
</style>