<template>
  <div>
    <!-- Header -->
    <h3 class="mb-10">Manage Loan</h3>

    <!-- Select Project -->
    <v-row justify="end">
      <v-col cols="12" sm="6" md="auto">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              outlined
              v-bind="attrs"
              class="text-capitalize select-project"
              :block="$vuetify.breakpoint.xs"
              v-on="on"
            >
              <v-img
                v-if="project.logo"
                :src="project.logo.uploadURL"
                width="48"
                height="48"
                contain
                class="logo-image"
              />
              {{ project.name ? project.name : 'Select project' }}
              <v-icon right size="24">
                {{ icons.mdiChevronDown }}
              </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="item in projects" :key="item.ID" link>
              <v-list-item-title
                @click="$store.commit('manageSalary/setCurrentProject', item)"
                v-text="item.name"
              ></v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <!-- Toolbar -->
    <v-row class="m-4 mt-10" justify="space-between">
      <v-row cols="6" sm="6" md="auto">
        <v-select
          :items="projects"
          label="Select Project"
          outlined
          dense
          hide-details
          style="max-width: 300px"
          class="mr-5"
        ></v-select>
        <v-btn depressed class="text-capitalize cmd-button" outlined>
          <v-icon dense color="black">
            {{ icons.mdiFilterVariant }}
          </v-icon>
          Filter
        </v-btn>
      </v-row>
      <v-spacer v-if="$vuetify.breakpoint.smAndUp" />
      <v-row cols="6" style="margin-top: -7px">
        <v-text-field
          v-model="search"
          :prepend-inner-icon="icons.mdiMagnify"
          label="Search invoice"
          single-line
          hide-details
          dense
          outlined
          class="mr-10"
        >
        </v-text-field>
        <v-btn depressed class="text-capitalize cmd-button" outlined>
          <v-icon dense color="black">
            {{ icons.mdiDownload }}
          </v-icon>
          Bulk Download
        </v-btn>
      </v-row>
    </v-row>

    <!-- Tabs -->
    <v-tabs class="mt-5">
      <v-tab
        class="text-capitalize"
        @click="
          () => {
            invoice = 'Incoming'
          }
        "
      >
        Incoming Invoice
      </v-tab>
      <v-tab
        class="text-capitalize"
        @click="
          () => {
            invoice = 'Outgoing'
          }
        "
      >
        Outgoing Invoice
      </v-tab>
    </v-tabs>
    <v-row cols="12" md="6" class="mt-5 mb-10" justify="space-between" align="center">
      <p v-if="invoice === 'Incoming'" class="incoming-amount py-2 ml-3">
        Total incoming amount <span style="color: #f15858; font-weight: bold">Rp.387.000.000</span>
      </p>
      <p v-if="invoice === 'Outgoing'" class="outgoing-amount py-2 ml-3">
        Total outgoing amount <span style="color: #ff6666; font-weight: bold">Rp.387.000.000</span>
      </p>
      <v-btn outlined color="primary" class="mr-3"> Download </v-btn>
    </v-row>
    <ListInvoice />
  </div>
</template>

<script>
import useAppConfig from '@core/@app-config/useAppConfig'
import { ref } from '@vue/composition-api'
import { mdiPlus, mdiMagnify, mdiFilterVariant, mdiDownload, mdiChevronDown } from '@mdi/js'
import ListInvoice from './components/ListInvoice.vue'

export default {
  name: 'ManageInvoice',
  setup() {
    const { isDark } = useAppConfig()
    const search = ref('')

    return {
      isDark,
      search,
      icons: {
        mdiPlus,
        mdiMagnify,
        mdiFilterVariant,
        mdiDownload,
        mdiChevronDown,
      },
    }
  },
  components: { ListInvoice },
  data() {
    return {
      invoice: 'Incoming',
    }
  },
  computed: {
    user() {
      return this.$store.getters['auth/getUser']
    },
    projects() {
      return this.$store.getters['manageProject/getProjects']
    },
    project() {
      return this.$store.getters['manageSalary/getCurrentProject']
    },
  },
  created() {
    this.$store.dispatch('manageProject/getProjects', { sub_id: this.user.sub_id })
  },
}
</script>

<style lang="scss" scoped>
.incoming-amount {
  background-color: #e8f4f5;
  color: #f15858;
  width: 350px;
  text-align: center;
  border-radius: 8px;
}
.outgoing-amount {
  background-color: #ffe0e0;
  color: #ff6666;
  width: 350px;
  text-align: center;
  border-radius: 8px;
}
.v-text-field.v-text-field--solo .v-input__control {
  min-height: 5px;
  height: 5px;
}
.v-btn.action-btn {
  width: 36px;
  min-width: 36px;
}

.v-btn.select-project {
  padding: 0;
  border: none;
  font-size: 18px;
  font-weight: bold;
  height: 48px !important;

  .logo-image {
    border: 1px solid lightgrey;
    border-radius: 3px;
    margin-right: 1rem;
  }

  span.v-ripple__container {
    opacity: 0 !important;
  }
}

.select-project:before {
  opacity: 0 !important;
}
</style>
