var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.invoices,"item-key":"name","show-select":""},scopedSlots:_vm._u([{key:"item.noInvoice",fn:function(ref){
var item = ref.item;
return [(_vm.$vuetify.breakpoint.smAndUp)?_c('v-col',{attrs:{"no-gutters":""}},[_c('p',{staticStyle:{"color":"black"}},[_vm._v(" "+_vm._s(item.noInvoice === "" ? "--" : item.noInvoice)+" ")]),_c('p',{staticStyle:{"margin-top":"-16px"}},[_vm._v(" "+_vm._s(item.createdAt === "" ? "--" : item.createdAt)+" ")])]):_vm._e()]}},{key:"item.category",fn:function(ref){
var item = ref.item;
return [(_vm.$vuetify.breakpoint.smAndUp)?_c('v-col',{attrs:{"no-gutters":""}},[_c('p',{staticStyle:{"color":"black"}},[_vm._v(" "+_vm._s(item.category === "" ? "--" : item.category)+" ")]),_c('p',{staticStyle:{"margin-top":"-16px"}},[_vm._v(" "+_vm._s(item.tag === "" ? "--" : item.tag)+" ")])]):_vm._e()]}},{key:"item.reciepents",fn:function(ref){
var item = ref.item;
return [(_vm.$vuetify.breakpoint.smAndUp)?_c('v-col',{attrs:{"no-gutters":""}},[_c('p',{staticStyle:{"color":"black"}},[_vm._v(" "+_vm._s(item.reciepents === "" ? "--" : item.reciepents)+" ")]),_c('p',{staticStyle:{"margin-top":"-16px"}},[_vm._v(" "+_vm._s(item.sentTo === "" ? "--" : item.sentTo)+" ")])]):_vm._e()]}},{key:"item.createdBy",fn:function(ref){
var item = ref.item;
return [(_vm.$vuetify.breakpoint.smAndUp)?_c('v-col',{attrs:{"no-gutters":""}},[_c('p',{staticStyle:{"color":"black"}},[_vm._v(" "+_vm._s(item.createdBy === "" ? "--" : item.createdBy)+" ")]),_c('p',{staticStyle:{"margin-top":"-16px"}},[_vm._v(" "+_vm._s(item.email === "" ? "--" : item.email)+" ")])]):_vm._e()]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [(_vm.$vuetify.breakpoint.smAndUp)?_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[_c('v-col',{staticClass:"mr-2 mt-1"},[_vm._v(" "+_vm._s(item.amount === "" ? "--" : item.amount)+" ")]),_c('v-col',[_c('v-btn',{staticClass:"action-btn me-4",style:(_vm.isDark 
              ? { 'border-color': '#5E5669AD' } 
              : { 'border-color': 'lightgray' }),attrs:{"small":"","outlined":"","color":"primary"},on:{"click":_vm.toDetail}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiEye))])],1)],1)],1):_vm._e()]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }